import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"
import { COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT } from "@/pages/account/variables/CommissionTypeVariables"

export default [
    {
        name: "commission-rules.index",
        path: "settings/commission-rules",
        component: () => import("@/pages/commissionRules/CommissionRulesIndex"),
        beforeEnter: onlyAllowedCommissionRules,
        meta: {
            title: "Regras de comissões",
            description: "Gerencie as regras de comissões.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Regras de comissões",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedCommissionRules(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    const customConfigStore = getStore().getters["customConfig/get"]

    if (!currentUser.account.flags.allows_forecast || parseInt(customConfigStore.COMMISSION_TYPE) !== COMMISSION_TYPE_ROLE_INVOLVED_IN_FORECAST_PAYMENT) {
        return next({ name: "notfound", query: { errorId: 403 }})
    }

    next()
}
