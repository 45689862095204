import state from "@/store/goalNew/state"
import mutations from "@/store/goalNew/mutations"
import * as actions from "@/store/goalNew/actions"
import * as getters from "@/store/goalNew/getters"

const module = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default module
