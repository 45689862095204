import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        name: "items.index",
        path: "settings/items",
        component: () => import("@/pages/itemsSettings/ItemsIndex"),
        beforeEnter: onlyAllowedItems,
        meta: {
            title: "Produtos, serviços ou MRR",
            description: "Gerencie os produtos, serviços e MRRs que serão utilizados na elaboração de propostas de oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Produtos, serviços ou MRR",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedItems(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_items) {
        return next({ name: "notfound", query: { errorId: 403 }})
    }

    next()
}
