import router from "@/router"
import VueMixPanel from "vue-mixpanel"
import PrimeVue from "primevue/config"
import PipeDocument from "@/plugins/pipe-document"
import PipeStaticResources from "@/plugins/pipe-static-resources"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import environment from "@/shared/environment"
import httpPlugin from "@/plugins/http"
import { getStore } from "@/store"

export function load(app) {
    app.use(PrimeVue)
    PipeDocument(app)
    httpPlugin(app, { store: getStore(), router })
    PipeStaticResources(app)

    app.use(VueMixPanel, {
        token: environment.APP_MIXPANEL_TOKEN,
    })

    Sentry.init({
        app,
        dsn: environment.APP_SENTRY_DSN,
        environment: environment.APP_SENTRY_ENVIRONMENT,
        release: environment.APP_SENTRY_RELEASE,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["app.pipe.run", "api.pipe.run", /^\//],
            }),
        ],
        allowUrls: ["pipe.run"],
        tracesSampleRate: 0.0,
        trackComponents: true,
        logErrors: true,
        ignoreErrors: [
            /Rendering cancelled, page/,
            /Cannot read properties of undefined \(reading 'catch'\)/,
        ],
    })
}
