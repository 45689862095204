import { http } from "@/plugins/http"

export async function list(params = {}) {
    const { data } = await http.get("talk/services", { params })

    return data
}

export async function get(id, params = {}) {
    const { data: { data } } = await http.get(`talk/services/${id}`, { params })

    return data
}

export async function create(payload) {
    const { data: { data } } = await http.post("talk/services", payload)

    return data
}

export async function update(id, payload) {
    const { data: { data } } = await http.put(`talk/services/${id}`, payload)

    return data
}

export async function remove(id) {
    await http.delete(`talk/services/${id}`)
}

export default {
    list,
    get,
    create,
    update,
    remove,
}
