import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"
import environment from "@/shared/environment"

export default [
    {
        name: "administration.account.management",
        path: "administration/account/management",
        components: {
            default: () => import("@/pages/administration/AccountManagementIndex"),
        },
        meta: {
            pageTitle: "Gerenciar Contas | Administração",
            title: "Gerenciar Contas",
            description: "Gerenciar Contas",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Gerenciar Contas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "administration.account.management.netsuite",
        path: "administration/account/management/netsuite",
        component: () => import("@/pages/administration/accountManagement/AccountManagementNetSuite"),
        meta: {
            title: "Gerenciar contas | NetSuite",
            description: "Gerenciar contas | NetSuite",
            accessPermission: "Administrador",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Administração NetSuite",
                    show: ".*",
                },
            ],
        },
    },
]
