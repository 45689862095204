import environment from "@/shared/environment"

export default [
    {
        path: "goals",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "goals.index",
                path: "",
                component: () => import("@/pages/goals/GoalsIndex"),
                meta: {
                    title: "Metas",
                    accessPermission: "C1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "goals.create",
                path: "create",
                component: () => import("@/pages/goals/GoalsCreate"),
                meta: {
                    title: "Cadastrar meta",
                    accessPermission: "G1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas",
                            href: "/v2/goals",
                            show: ".*",
                        },
                        {
                            label: "Cadastrar meta",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "goals.edit",
                path: "edit/:id",
                component: () => import("@/pages/goals/GoalsEdit"),
                props: true,
                meta: {
                    title: "Editar meta",
                    accessPermission: "G1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas",
                            href: "/v2/goals",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "goals.duplicate",
                path: "duplicate/:id",
                component: () => import("@/pages/goals/GoalsDuplicate"),
                props: true,
                meta: {
                    title: "Duplicar meta",
                    accessPermission: "G1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Metas",
                            href: "/v2/goals",
                            show: ".*",
                        },
                        {
                            label: "Duplicar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]
