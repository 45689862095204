import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

const routesMeta = {
    title: "Templates de e-mail",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Templates de e-mail",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/templates-email",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "settings.templatesEmailSettings.index",
                path: "",
                component: () => import("@/pages/templatesEmailSettings/TemplatesEmailIndex"),
                meta: {
                    ...routesMeta,
                },
            },
            {
                path: "text",
                children: [
                    {
                        name: "settings.templatesEmailSettings.text.create",
                        path: "create",
                        component: () => import("@/pages/templatesEmailSettings/text/TemplatesEmailCreate"),
                        meta: {
                            title: "Novo template de e-mail",
                            accessPermission: "G1",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de e-mail",
                                    href: "/v2/settings/templates-email/",
                                    show: ".*",
                                },
                                {
                                    label: "Novo",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                    {
                        name: "settings.templatesEmailSettings.text.edit",
                        path: "edit/:id",
                        props: true,
                        component: () => import("@/pages/templatesEmailSettings/text/TemplatesEmailEdit"),
                        meta: {
                            title: "Editar template de e-mail",
                            accessPermission: "G1",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de e-mail",
                                    href: "/v2/settings/templates-email/",
                                    show: ".*",
                                },
                                {
                                    label: "Editar",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: "visual",
                beforeEnter(to, from, next) {
                    const currentUser = getStore().getters["auth/currentUser"]
                    if (!currentUser.account.flags.allows_visual_email_template) {
                        return next({ name: "settings.templatesEmailSettings.index" })
                    }

                    next()
                },
                children: [
                    {
                        name: "settings.templatesEmailSettings.visual.create",
                        path: "create",
                        component: () => import("@/pages/templatesEmailSettings/visual/TemplateEmailCreate"),
                        meta: {
                            title: "Template de e-mail",
                            accessPermission: "G1",
                            breadcrumb: [
                                ...SettingsBreadcrumbs,
                                {
                                    label: "Templates de e-mail",
                                    href: "/v2/settings/templates-email/",
                                    show: ".*",
                                },
                                {
                                    label: "Novo",
                                    show: ".*",
                                },
                            ],
                        },
                    },
                    {
                        name: "settings.templatesEmailSettings.visual.edit",
                        path: "edit/:id",
                        props: true,
                        component: () => import("@/pages/templatesEmailSettings/visual/TemplateEmailUpdate"),
                        meta: {
                            accessPermission: "G1",
                        },
                    },
                ],
            },
        ],
    },
]
