import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        name: "automaticMovements.index",
        path: "settings/automatic-movements",
        component: () => import("@/pages/automaticMovementsSettings/AutomaticMovementsIndex"),
        beforeEnter(to, from, next) {
            const currentUser = getStore().getters["auth/currentUser"]
            if (!currentUser.account.flags.allows_automatic_movement) {
                return next({ name: "notfound", query: { errorId: 403 }})
            }

            next()
        },
        meta: {
            title: "Cadência de funil",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Cadência de funil",
                    show: ".*",
                },
            ],
        },
    },
]

