import { http } from "@/plugins/http"

/**
 * @param payload {{
 *     title: string,
 *     visibility: number,
 *     type: number,
 *     goal_for?: number,
 *     observation?: string,
 *     start_at: string,
 *     end_at: string,
 *     item_criteria: number,
 *     item_name?: string,
 *     activity_type_id?: number,
 *     origin_id?: number,
 *     region_id?: number,
 *     city_id?: number,
 *     state?: string,
 *     segment_id?: number,
 *     active: boolean,
 *     calculate_item_by_unit_value: boolean,
 *     item_criterias: object,
 *     deal_status: object,
 *     proposal_status: object,
 *     deal_value_for: object,
 *     activity_status: object,
 *     call_relevance: object,
 *     signature_status: object,
 *     pipelines?: object,
 *     stages?: object,
 *     tags?: object,
 *     item_categories?: object,
 *     custom_fields?: object,
 *     users: object,
 * }}
 * @param params {{
 *     with?: string,
 *     sort?: string,
 *     desc?: boolean,
 *     show?: number,
 *     page?: number,
 * }}
 * @returns {Promise<{ object }>}
 */
export async function create(payload, params = {}) {
    const { data: { data } } = await http.post("goals-v2", payload, { params })

    return data
}

/**
 * @param {number} id
 * @param payload {{
 *     title: string,
 *     visibility: number,
 *     type: number,
 *     goal_for?: number,
 *     observation?: string,
 *     start_at: string,
 *     end_at: string,
 *     item_criteria: number,
 *     item_name?: string,
 *     activity_type_id?: number,
 *     origin_id?: number,
 *     region_id?: number,
 *     city_id?: number,
 *     state?: string,
 *     segment_id?: number,
 *     active: boolean,
 *     calculate_item_by_unit_value: boolean,
 *     item_criterias: object,
 *     deal_status: object,
 *     proposal_status: object,
 *     deal_value_for: object,
 *     activity_status: object,
 *     call_relevance: object,
 *     signature_status: object,
 *     pipelines?: object,
 *     stages?: object,
 *     tags?: object,
 *     item_categories?: object,
 *     custom_fields?: object,
 *     users: object,
 * }}
 * @returns {Promise<{ object }>}
 */
export async function update(id, payload) {
    const { data } = await http.put(`goals-v2/${id}`, payload)

    return data
}

/**
 * @param params {{
 *     with?: string,
 *     sort?: string,
 *     desc?: boolean,
 *     show?: number,
 *     page?: number,
 * }}
 *
 * @returns {Promise<void>}
 */
export async function list(params = {}) {
    const { data } = await http.get("goals-v2", { params })

    return data
}

/**
 * @param id {number}
 *
 * @returns {Promise<{ object }>}
 */
export async function remove(id) {
    const { data } = await http.delete(`goals-v2/${id}`)

    return data
}

/**
 * @param id {number}
 *
 * @param params {{
 *     with?: string,
 * }}
 *
 * @returns {Promise<{ object }>}
 */
export async function get(id, params) {
    const { data: { data } } = await http.get(`goals-v2/${id}`, { params })

    return data
}

/**
 * @param id {number}
 *
 * @param params {{
 *     with?: string,
 * }}
 *
 * @returns {Promise<{ object }>}
 */
export async function getStats(id, params = {}) {
    const { data: { data } } = await http.get(`goals-v2/${id}/stats`, { params })

    return data
}

export default {
    get,
    getStats,
    create,
    update,
    list,
    remove,
}
