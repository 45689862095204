import DOMPurify from "dompurify"

const DEFAULT_CONFIG = {
    ALLOWED_TAGS: [
        "a", "abbr", "b", "blockquote", "br", "caption", "code", "div", "em", "h1", "h2", "h3", "h4", "h5", "h6", "hr",
        "i", "li", "ol", "p", "pre", "s", "span", "strike", "strong", "sub", "sup", "ul", "u", "var",
        "table", "thead", "tbody", "tfoot", "tr", "th", "td",
    ],
    ALLOWED_ATTR: ["href", "src", "alt", "title", "class", "style"],
}

export default function useDomPurify() {
    function sanitize(content, config = DEFAULT_CONFIG) {
        return DOMPurify.sanitize(content, config)
    }

    return {
        sanitize,
    }
}
