import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

const regionsBreadcrumbs = [
    ...SettingsBreadcrumbs,
    {
        label: "Regiões",
        href: "/v2/settings/regions",
        show: ".*",
    },
]

const microregionsMeta = {
    title: "Microrregiões",
    accessPermission: "G1",
    description: "Gerencie suas microrregiões, vinculando cidades, usuários e definindo seus líderes.",
}

export default [
    {
        name: "region.index",
        path: "settings/regions",
        component: () => import("@/pages/regionSettings/RegionIndex"),
        beforeEnter: onlyAllowedRegionsMicroregions,
        meta: {
            title: "Regiões",
            description: "Gerencie suas regiões e microrregiões.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Regiões",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "region.microregions",
        path: "settings/regions/:region_id/microregions/:microregion_id?",
        component: () => import("@/pages/regionSettings/MicroregionIndex"),
        beforeEnter: onlyAllowedRegionsMicroregions,
        meta: {
            pageTitle: "Microrregiões",
            ...microregionsMeta,
            breadcrumb: [
                ...regionsBreadcrumbs,
                {
                    label: "-> Microrregiões",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "region.allMicroregions",
        path: "settings/regions/microregions",
        component: () => import("@/pages/regionSettings/MicroregionIndex"),
        beforeEnter: onlyAllowedRegionsMicroregions,
        meta: {
            pageTitle: "Microrregiões",
            ...microregionsMeta,
            breadcrumb: [
                ...regionsBreadcrumbs,
                {
                    label: "Microrregiões",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedRegionsMicroregions(to, from, next)  {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_regions_microregions) {
        return next({ name: "notfound", query: { errorId: 403 }})
    }
    next()
}
