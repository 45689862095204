import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        name: "itemCategories.index",
        path: "settings/item-categories",
        component: () => import("@/pages/itemCategoriesSettings/ItemCategoriesIndex"),
        beforeEnter: onlyAllowedItems,
        meta: {
            title: "Categorias e subcategorias",
            accessPermission: "G1",
            description: "Gerencie as categorias e subcategorias de produtos, serviços e mensalidades (MRR).",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Categorias e subcategorias",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedItems(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_items) {
        return next({ name: "notfound", query: { errorId: 403 }})
    }

    next()
}
