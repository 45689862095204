import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"
import {
    ACCOUNT_FLAG_ALLOWS_REGIONS_MICROREGIONS,
    ACCOUNT_FLAG_ALLOWS_CONTENT_CATEGORIES,
} from "@/router/routes/settings/variables/AccountFlagsVariables"

const META = {
    title: "Dados da conta",
    description: "Configure sua conta e gerencie usuários, equipes e grupos de equipe.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Conta",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/account",
        component: () => import("@/pages/account/AccountIndex"),
        meta: META,
        children: [
            {
                name: "account.index",
                path: "",
                component: () => import("@/pages/account/components/AccountData"),
                meta: META,
            },
            {
                path: "settings",
                component: () => import("@/pages/account/components/AccountSettings"),
                meta: META,
                children: [
                    {
                        name: "account.settings",
                        path: "",
                        redirect: { name: "account.settings.automatic-actions" },
                    },
                    {
                        name: "account.settings.automatic-actions",
                        path: "automatic-actions",
                        component: () => import("@/pages/account/components/settingsElements/AutomaticActionsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.internal-agenda",
                        path: "internal-agenda",
                        component: () => import("@/pages/account/components/settingsElements/InternalAgendaConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.deal-close-and-replicate",
                        path: "deal-close-and-replicate",
                        component: () => import("@/pages/account/components/settingsElements/DealCloseAndReplicate"),
                        meta: META,
                    },
                    {
                        name: "account.settings.mobile-config",
                        path: "mobile-config",
                        component: () => import("@/pages/account/components/settingsElements/MobileConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.electronic-digital-signature",
                        path: "electronic-digital-signature",
                        component: () => import("@/pages/account/components/settingsElements/ElectronicDigitalSignatureConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.activity",
                        path: "activity",
                        component: () => import("@/pages/account/components/settingsElements/ActivityConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.global-search",
                        path: "global-search",
                        component: () => import("@/pages/account/components/settingsElements/GlobalSearchConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.commissions-and-discounts",
                        path: "commissions-and-discounts",
                        component: () => import("@/pages/account/components/settingsElements/CommissionsAndDiscountsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.contacts",
                        path: "contacts",
                        component: () => import("@/pages/account/components/settingsElements/ContactsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.contents",
                        path: "contents",
                        component: () => import("@/pages/account/components/settingsElements/ContentsConfig"),
                        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CONTENT_CATEGORIES),
                        meta: META,
                    },
                    {
                        name: "account.settings.mail",
                        path: "mail",
                        component: () => import("@/pages/account/components/settingsElements/MailConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.export",
                        path: "export",
                        component: () => import("@/pages/account/components/settingsElements/ExportConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.forecast",
                        path: "forecast",
                        component: () => import("@/pages/account/components/settingsElements/ForecastConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.taxes",
                        path: "taxes",
                        component: () => import("@/pages/account/components/settingsElements/TaxesConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.brands",
                        path: "brands",
                        component: () => import("@/pages/account/components/settingsElements/BrandsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.microregions",
                        path: "microregions",
                        component: () => import("@/pages/account/components/settingsElements/MicroregionsConfig"),
                        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_REGIONS_MICROREGIONS),
                        meta: META,
                    },
                    {
                        name: "account.settings.notes",
                        path: "notes",
                        component: () => import("@/pages/account/components/settingsElements/NotesConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.deals",
                        path: "deals",
                        component: () => import("@/pages/account/components/settingsElements/DealsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.deals-pipeline-cards",
                        path: "deals-pipeline-cards",
                        component: () => import("@/pages/account/components/settingsElements/DealsPipelineCardsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.pipeline",
                        path: "pipeline",
                        component: () => import("@/pages/account/components/settingsElements/PipelineConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.proposal",
                        path: "proposal",
                        component: () => import("@/pages/account/components/settingsElements/ProposalConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.sequential-acronyms",
                        path: "sequential-acronyms",
                        component: () => import("@/pages/account/components/settingsElements/SequentialAcronymsConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.report",
                        path: "report",
                        component: () => import("@/pages/account/components/settingsElements/ReportConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.dynamic-ranking",
                        path: "dynamic-ranking",
                        component: () => import("@/pages/account/components/settingsElements/DynamicRankingConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.webphone",
                        path: "webphone",
                        component: () => import("@/pages/account/components/settingsElements/WebphoneConfig"),
                        meta: META,
                    },
                    {
                        name: "account.settings.user",
                        path: "user",
                        component: () => import("@/pages/account/components/settingsElements/UserConfig"),
                        meta: META,
                    },
                ],
            },
            {
                name: "account.users",
                path: "users",
                component: () => import("@/pages/account/components/AccountUsers"),
                meta: META,
            },
            {
                name: "account.teams",
                path: "teams",
                component: () => import("@/pages/account/components/AccountTeams"),
                meta: META,
            },
            {
                name: "account.team-groups",
                path: "team-groups",
                component: () => import("@/pages/account/components/AccountTeamGroups"),
                meta: META,
            },
            {
                name: "account.logs",
                path: "logs",
                component: () => import("@/pages/account/components/AccountLogs"),
                meta: META,
            },
            {
                name: "account.crma",
                path: "crma",
                component: () => import("@/pages/account/components/AccountCrma"),
                meta: META,
            },
        ],
    },
]
function onlyAllowedFlag(flag) {
    return function (to, from, next) {
        const currentUser = getStore().getters["auth/currentUser"]
        if (!currentUser.account.flags[flag]) {
            return next({ name: "notfound", query: { errorId: 403 }})
        }

        next()
    }
}
