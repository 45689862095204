import { list as GoalNewServiceList } from "@/services/goalNew/GoalNewService"

export const list = ({ commit }, params) => {
    return new Promise((resolve, reject) => {
        GoalNewServiceList(params).then(({ data, meta }) => {
            const list = { data, meta }
            commit("SET_DATA", { list })
            resolve({ list })
        }, error => {
            reject(error)
        })
    })
}

