import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        path: "settings/landing-page",
        component: () => import("@/layouts/EmptyRouterLayout"),
        beforeEnter(to, from, next) {
            const currentUser = getStore().getters["auth/currentUser"]
            if (!currentUser.account.flags.allows_landing_page) {
                return next({ name: "notfound", query: { errorId: 403 }})
            }

            next()
        },
        children: [
            {
                name: "landing-page.index",
                path: "",
                component: () => import("@/pages/landingPage/LandingPageIndex"),
                meta: {
                    title: "Landing Page",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Landing Page",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "landing-page.create",
                path: "create",
                component: () => import("@/pages/landingPage/LandingPageCreate"),
                meta: {
                    title: "Criar Landing Page",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Landing Page",
                            show: ".*",
                            href: "/v2/settings/landing-page",
                        },
                        {
                            label: "Criar",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "landing-page.edit",
                path: "edit/:id",
                accessPermission: "G1",
                component: () => import("@/pages/landingPage/LandingPageUpdate"),
                meta: {
                    accessPermission: "G1",
                },
                props: true,
            },
        ],
    },
]
