import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { getStore } from "@/store"

export default [
    {
        name: "documentModels.index",
        path: "settings/document-models",
        component: () => import("@/pages/documentModelsSettings/DocumentModelsSettingsIndex"),
        beforeEnter: onlyAllowedOldDocumentModel,
        meta: {
            title: "Modelos de documento",
            description: "Gerencie os modelos de documento.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Modelos de documento",
                    show: ".*",
                },
            ],
        },
    },
    {
        path: "settings/documents/models",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "documents.models.index",
                path: "",
                component: () => import("@/pages/documents/models/DocumentModelsIndex"),
                meta: {
                    title: "Modelos de documento",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Modelos de documento",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "documents.models.create",
                path: "create",
                component: () => import("@/pages/documents/models/DocumentModelCreate"),
                meta: {
                    title: "Novo modelo de documento",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Modelos de documento",
                            href: "/v2/settings/documents/models",
                            show: ".*",
                        },
                        {
                            label: "Novo",
                            show: ".*",
                        },
                    ],
                },
            },
            {
                name: "documents.models.edit",
                path: "edit/:id",
                component: () => import("@/pages/documents/models/DocumentModelEdit"),
                props: true,
                meta: {
                    title: "Editar modelo de documento",
                    accessPermission: "G1",
                    breadcrumb: [
                        ...SettingsBreadcrumbs,
                        {
                            label: "Modelos de documento",
                            href: "/v2/settings/documents/models",
                            show: ".*",
                        },
                        {
                            label: "Editar",
                            show: ".*",
                        },
                    ],
                },
            },
        ],
    },
]

function onlyAllowedOldDocumentModel(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser?.account.flags.allows_old_document_model) {
        return next({ name: "documents.models.index" })
    }

    next()
}
