import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

export default [
    {
        name: "administration.talk.channels.index",
        path: "administration/talk/channels",
        component: () => import("@/pages/administration/talk/TalkChannelsIndex"),
        meta: {
            pageTitle: "Canais",
            title: "Canais",
            description: "Crie e configure seus canais de atendimento.",
            accessPermission: "Administrador",
            breadcrumb: [
                ...AdministrationBreadcrumbs,
                {
                    label: "Canais",
                    show: ".*",
                },
            ],
        },
        children: [
            {
                name: "administration.talk.channels.create",
                path: "create",
                component: () => import("@/pages/administration/talk/TalkChannelsCreate"),
            },
            {
                name: "administration.talk.channels.edit",
                path: ":id/edit",
                props: true,
                component: () => import("@/pages/administration/talk/TalkChannelsEdit"),
            },
        ],
    },
]
