import environment from "@/shared/environment"
import { getStore } from "@/store"

const contentBreadcrumbs = [
    {
        label: "Home",
        href: environment.APP_YII,
        show: ".*",
        external: true,
    },
    {
        label: "Categorias",
        href: "/v2/content/categories",
        show: ".*",
    },
]

export const ContentDefault = [
    {
        name: "categories.index",
        path: "content/categories",
        beforeEnter: onlyAllowedContent,
        component: () => import("@/pages/categories/CategoryIndex"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            title: "Categorias",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Categorias",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "categories.view",
        path: "content/categories/:categoryId",
        component: () => import("@/pages/categories/CategoryView"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            title: "Categoria: ",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    show: ".*",
                },
            ],
        },
    },
]

export const ContentFullHeight = [
    {
        name: "content.add",
        path: "content/categories/:categoryId/add",
        beforeEnter: onlyAllowedContent,
        component: () => import("@/pages/content/ContentAdd"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    href: "/v2/content/categories/",
                    show: ".*",
                },
                {
                    label: "Nova Página",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "content.edit",
        path: "content/categories/:categoryId/:pageId/edit",
        component: () => import("@/pages/content/ContentAdd"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    href: "/v2/content/categories/",
                    show: ".*",
                },
                {
                    label: "",
                    show: ".*",
                },
            ],
        },
    },
]

function onlyAllowedContent(to, from, next) {
    const currentUser = getStore().getters["auth/currentUser"]
    if (!currentUser.account.flags.allows_content_categories) {
        window.location = `${environment.APP_YII}/pipeline`
    }

    next()
}
