import { setToken as httpSetToken } from "@/plugins/http"
import * as Sentry from "@sentry/vue"
import { Userpilot } from "userpilot"
import environment from "@/shared/environment"
import { generateHmacHash } from "@/shared/helpers/cryptoHelper"
import { loadElevio }  from "@/libraries/elevio"

const plugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, { auth, customConfig }) => {
        if (mutation.type === "auth/SET_TOKEN") {
            httpSetToken(auth.token)
        }

        if (mutation.type === "auth/SET_USER") {
            const { user } = auth

            Sentry.setUser({
                id: user.id,
                email: user.email.trim(),
                username: user.name.trim(),
                account_id: user.account.id,
                permission: user.acl.trim(),
            })

            Sentry.setTag("user.account", user.account.id)

            if (window.location.search.indexOf("disable_elevio") === -1) {
                loadElevio(() => initElevio(user))
            }
        }

        if (auth.user && mutation.type === "customConfig/SET_CUSTOM_CONFIGS") {
            initUserpilot(auth.user, customConfig.customConfigs)
        }
    })
}

async function initUserpilot(user, customConfigs) {
    if (!environment.APP_USERPILOT_TOKEN) {
        return false
    }
    Userpilot.initialize(environment.APP_USERPILOT_TOKEN)

    let companyData = {
        id: user.account.id,
        name: user.account.trading_name,
        created_at: user.account.created_at,
        expires_at: user.account.expires_at,
        plan_type: user.account_details.plan_name.trim(),
        created_segment: customConfigs["ACCOUNT_CREATED_IN_SEGMENT"],
    }

    Userpilot.identify(
        user.id,
        {
            name: user.name.trim(),
            email: user.email.trim(),
            telephone: user.cellphone,
            created_at: user.user_details.user_created_at,
            signed_up: user.user_details.user_created_at,
            role: user.acl.trim(),
            app_language: "pt-BR",
            plan_type: user.account_details.plan_name.trim(),
            gender: user.gender,
            company: companyData,
        }
    )
}

async function initElevio(user) {
    if (!environment.APP_ELEVIO_ID) {
        return false
    }

    const PIPERUN_PLAN_ID = 45
    if (user.account.plan_id === PIPERUN_PLAN_ID) {
        const pipeElevioAccountSecret = "618d8a141e7df"
        const generatedPiperunEmailHash = await generateHmacHash(pipeElevioAccountSecret, user.email)

        window._elev.on("load", function (_elev) {
            _elev.setUser({
                email: user.email.trim(),
                user_hash: generatedPiperunEmailHash,
            })
        })
    }
}

export default plugin
