/* eslint-disable no-console */
import { setTokens, getTokens } from "@/plugins/auth"
import { http } from "@/plugins/http"
import { post } from "axios"
import environment from "@/shared/environment"

function postRenewedLogin() {
    if (typeof BroadcastChannel !== "undefined") {
        const channel = new BroadcastChannel("pprn.broadcast.channel")
        channel.postMessage({ renewedLogin: true })
    }
}

/* Autentica com username e senha e retorna true */
export const authWithCredentials = async ({ dispatch, state }, { username, password }) => {
    return await post(`${environment.APP_API}/token`, {
        username: username,
        password: password,
        grant_type: "password",
        client_id: environment.APP_CLIENT_ID,
    }, {
        headers: {
            "X-Application-Piperun": "CRM_Web_VueJs",
        },
    }).then(async ({ data }) => {
        await dispatch("saveTokens", data)
        postRenewedLogin()

        /* Retorna tokens para apreciação */
        return Promise.resolve(state.token)
    }).catch(({ response }) => Promise.reject(response.data))
}

/* Autentica com o cookie e retorna access_token */
export const authWithCookies = async ({ dispatch, state }) => {
    /* Busca nos cookies os tokens */
    const tokens = getTokens()

    /* Se houver os tokens, salva o user na state, ou então rejeita a promise */
    if (tokens) {
        await dispatch("saveTokens", tokens)
    } else {
        return Promise.reject("There is no credentials available.")
    }

    return Promise.resolve(state.token)
}

export const authWithSocial = async ({ dispatch, state }, {code, redirect}) => {
    return await post(`${environment.APP_API}/token`, {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: redirect,
        client_id: environment.APP_CLIENT_ID,
    }, {
        headers: {
            "X-Application-Piperun": "CRM_Web_VueJs",
        },
    }).then(async ({ data }) => {
        await dispatch("saveTokens", data)
        postRenewedLogin()

        /* Retorna tokens para apreciação */
        return Promise.resolve(state.token)
    }).catch(({ response }) => Promise.reject(response.data.message))
}

/* Valida refresh token e retorna o access_token */
export const refreshToken = async ({ dispatch, state }) => {
    /* Requer a existência do refresh */
    if (!state.refreshToken) {
        return false
    }

    return await post(`${environment.APP_API}/token`, {
        grant_type: "refresh_token",
        refresh_token: state.refreshToken,
        client_id: environment.APP_CLIENT_ID,
    }, {
        headers: {
            "X-Application-Piperun": "CRM_Web_VueJs",
        },
    }).then(async ({ data }) => {
        await dispatch("saveTokens", data)
        /* Retorna tokens para apreciação */
        return Promise.resolve(state.token)
    }).catch(error => {
        /* Retorna um erro que é capturado pelo interceptors.js */
        if (error.response.status === 402) {
            window.location = "/logout"
        }
        return Promise.reject(error)
    })
}

/* Salva usuário e permissão do usuário */
export const saveUser = async ({ commit, state }) => {
    await http.get("me").then(({ data }) => {
        commit("SET_USER", data.data)
        commit("SET_ACL", data.data.acl)

        const isFirstLogin = !data.data.user_details.user_last_access

        if (isFirstLogin) {
            window.localStorage.setItem("@piperun/isFirstLogin", true)
        } else {
            window.localStorage.removeItem("@piperun/isFirstLogin")
        }

        return data.data
    })

    return state.user
}

/* Salva os tokens */
export const saveTokens = async ({ commit, state, dispatch }, tokenData) => {
    let { token_type, access_token, refresh_token } = tokenData

    if (token_type) {
        access_token = `${token_type} ${access_token}`
    }

    if (access_token || refresh_token) {
        /* No state */
        commit("SET_TOKEN", access_token)
        commit("SET_REFRESH", refresh_token)
        /* No cookie */
        setTokens(access_token, refresh_token)
        /* Atualiza os dados do usuário logado */
        await dispatch("saveUser")
    } else {
        return Promise.reject("Can't save tokens because they're empty.")
    }

    return {
        accessToken: state.token,
        refreshToken: state.refreshToken,
    }
}
