import plugin from "@/store/auth/plugin"

import auth from "@/store/auth"
import company from "@/store/company"
import currencies from "@/store/currencies"
import customConfig from "@/store/customConfig"
import proposalParcels from "@/store/proposalParcels"
import quickViewer from "@/store/quickViewer"
import customForm from "@/store/customForm"
import goalNew from "@/store/goalNew"

import { createStore } from "vuex"

let store = null

export function getStore() {
    if (!store) {
        store = createStore({
            modules: {
                auth,
                company,
                currencies,
                customConfig,
                proposalParcels,
                quickViewer,
                customForm,
                goalNew,
            },
            plugins: [plugin],
        })
    }

    return store
}
